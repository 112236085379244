import { defineStore } from "pinia";

export const useDonorsAuthStore = defineStore("donorsAuth", () => {
  const donor = persistedState.localStorage.getItem("donor");

  watch(
    () => donor,
    (state) => {
      localStorage.setItem("donor", JSON.stringify(state));
    },
    { deep: true }
  );

  async function logIn(donor) {
    window.localStorage.clear();
    await donorsFetch("/auth", {
      method: "POST",
      body: {
        email: donor.email,
        password: donor.password,
      },
    }).then((response) => {
      if (response.authenticated) {
        persistedState.localStorage.setItem("donor", response.donor);
        this.donor = persistedState.localStorage.getItem("donor");
        persistedState.localStorage.setItem("token", response.token);
        document.cookie =
          "donor=" +
          response.donor +
          "; path=/; domain=.allodons.fr; Secure; SameSite=Lax";
        document.cookie =
          "token=" +
          response.token +
          "; path=/; domain=.allodons.fr; Secure; SameSite=Lax";
      } else {
        window.localStorage.clear();
      }
    });
  }

  function logOut() {
    window.localStorage.clear();
    window.location.reload();
    document.cookie =
      "token=; path=/; domain=.allodons.fr; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie =
      "donor=; path=/; domain=.allodons.fr; expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  function currentToken() {
    return persistedState.localStorage.getItem("token");
  }

  return {
    donor,
    logIn,
    logOut,
    currentToken,
  };
});
